<template>
  <div class="display-images">
    <div class="display-images-header">
      <el-button @click="$router.push({path:'/admin/goods/display'})">返回类型列表</el-button>
      <el-button @click="addDisplayWrap = true;add = true">添加陈列图</el-button>
      <el-dialog title="陈列照" :visible.sync="addDisplayWrap" width="35%">
        <el-form ref="form" label-width="130px">
          <el-form-item label="名称">
            <el-input v-model="displayForm.name"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="displayForm.order"></el-input>
          </el-form-item>
          <el-form-item label="系列选购界面展示" v-if="seriesCategoryType === categoryType" >
            <el-select v-model="displayForm.is_show_at_choose_goods" placeholder="请选择">
              <el-option v-for="item in is_show_at_choose_goods_option" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="displayForm.status" placeholder="请选择">
              <el-option v-for="item in statusOption" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="展示图">
            <el-upload
              class="avatar-uploader"
              :action="this.$root.DownUrl + '/a1/upload/file'"
              :headers="uploadHeaders"
              :show-file-list="false"
              :on-success="handleImgSuccess"
              :auto-upload="true"
              list-type="picture"
            >
              <img v-if="displayForm.src" :src="displayForm.src" class="avatar" alt="" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="添加附件">
            <el-upload
              class="avatar-uploader"
              :action="this.$root.DownUrl + '/a1/upload/file'"
              :headers="uploadHeaders"
              :show-file-list="true"
              :on-success="handleFileSuccess"
              :auto-upload="true"
              list-type="text"
              :file-list="fileList"
              :limit="1"
            >
              <i class="el-icon-plus avatar-uploader-file"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
				  <el-button @click="addDisplayWrap = false">取 消</el-button>
				  <el-button type="primary" @click="submitDisplayWrap" v-if="add">提 交</el-button>
				  <el-button type="primary" @click="editDisplayWrap" v-if="!add">编 辑</el-button>
			  </span>
      </el-dialog>
    </div>
    <div class="display-images-content">
      <el-table :data="imagesData">
<!--        <el-table-column prop="id" label="id" width="80"></el-table-column>-->
        <el-table-column prop="categoryid" label="关联ID"></el-table-column>
        <el-table-column prop="imagesName" label="名称"></el-table-column>
        <el-table-column prop="imagesSrc" label="图片/文件">
          <template v-slot="{ row }">
            <el-tooltip v-if="row.filesrc" class="item" effect="dark" content="点击下载附件" placement="right">
              <el-image title="下载附件" class="banner-viewer" :src="row.imagesSrc" @click="handleDownFile(row)"></el-image>
            </el-tooltip>
            <el-image v-else class="banner-viewer" :src="row.imagesSrc" :preview-src-list="[row.imagesSrc]"></el-image>
          </template>
        </el-table-column>
        <el-table-column v-if="seriesCategoryType === categoryType" label="系列选购界面展示" prop="is_show_at_choose_goods">
          <template v-slot="{ row }">
            <template v-for="item in is_show_at_choose_goods_option">
            <template v-if="item.value ===row.is_show_at_choose_goods ">
              {{ item.label }}
            </template>
          </template>
          </template>
         
        </el-table-column>
        <el-table-column prop="imagesOrder" label="排序"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column label="操作" width="300">
          <template v-slot="{row}">
            <el-button type="primary" plain @click="handleEdit('edit',row)">编辑</el-button>
            <el-button type="danger" plain @click="handleEdit('del',row)">删除</el-button>
            <el-button type="primary" plain @click="handleEdit('show',row)">绑定商品</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div ref="display-images-content-footer" class="renew-data-footer" v-if="imagesData.length > 0">
        <el-pagination :key="1" :current-page="imagesDataPageInfo.page" :page-size="imagesDataPageInfo.pagesize"
                       :page-sizes="[10, 20, 50, 100, 200]" layout="total,sizes, prev, pager, next, jumper"
                       :total="imagesDataPageInfo.total"
                       @size-change="handleRenewRenewSizeChange"
                       @current-change="handleChangeCurrentPage"
                       background />
      </div>
    </div>
    <div class="bind-goods">
      <el-drawer
        :title="'绑定陈列商品,总价 : ' +  bindTotal +' 元 '"
        :visible.sync="bindGoods"
        direction="rtl"
        size="45%">
        <div class="add">
          <el-form ref="form" label-width="80px" :inline="true">
            <el-form-item label="商品编号">
              <el-input type="text" v-model="bindForm.goods_item" clearable />
            </el-form-item>
            <el-form-item label="规格">
              <el-input type="nnumber" v-model="bindForm.goods_number" clearable />
            </el-form-item>
            <el-button type="primary" @click="submitBindGoods">提 交</el-button>
          </el-form>
        </div>
        <div class="content">
          <el-table :data="bindData">
<!--            <el-table-column label="商品名称"></el-table-column>-->
            <el-table-column label="商品编码" prop="goods_item" />
            <el-table-column label="规格" prop="bind_number" />
            <el-table-column label="单价" prop="goods_price" />
<!--            <el-table-column label="总价"></el-table-column>-->
            <el-table-column>
              <template v-slot="{row}">
                <el-button @click="handleUnbind(row)">解除绑定</el-button>
              </template>
            </el-table-column>
          </el-table>
<!--          <el-pagination :key="1" :current-page="bindDataPageInfo.page" :page-size="bindDataPageInfo.pagesize" layout="total,sizes, prev, pager, next, jumper"
                         :total="bindDataPageInfo.total"
                         @size-change="handleRenewRenewSizeChange"
                         @current-change="handleChangeBindData"
                         background />-->
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: "displayinfo",
  data() {
    return {
      categoryid: 0,
      addDisplayWrap: false,
      displayForm: {
        name: "",
        src: "",
        order: 0,
        status: 0,
        filesrc:"",
        is_show_at_choose_goods:0
      },
      fileList:[],
      add: false,
      imagesData: [],
      imagesDataPageInfo: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
      currentid: 0,
      statusOption: [
        { label: "展示", value: 1 },
        { label: "隐藏", value: 0 },
      ],
      is_show_at_choose_goods_option:[
        { label: "不显示", value: 1 },
        { label: "显示", value: 0 },
      ],
      uploadHeaders: {
        Authorization: JSON.parse(sessionStorage.getItem("token")),
      },
      bindGoods:false,
      bindForm:{
        imagesid:'',
        goods_item:'',
        goods_number:""
      },
      bindData:[],
      bindTotal:0,
      bindDataPageInfo:{
        page:1,
        pagesize:10,
        total:0
      },
      // 陈列类型（用于判断显示系列展示字段）
      categoryType:"",
      seriesCategoryType:"2" //系列产品类型
    };
  },
  created() {

    const { categoryid ,categoryType}=this.$route.query;
    this.categoryType=categoryType

    if (categoryid === undefined || categoryid === "" || categoryid === "0") {
      this.$router.push({ path: "/admin/goods/display" });
    }

    this.categoryid=categoryid;

    this.handleGetDisplayImages();

  },
  methods: {
    submitDisplayWrap() {
      let params={
        categoryid: parseInt(this.categoryid),
        name: this.displayForm.name,
        src: this.displayForm.src,
        filesrc: this.displayForm.filesrc,
        order: parseInt(this.displayForm.order),
        status: parseInt(this.displayForm.status),
        is_show_at_choose_goods:parseInt(this.displayForm.is_show_at_choose_goods)
      };

      this.$axios.post("/a1/display/category/images", params).then(() => {

        this.$message.success("添加成功");
        this.addDisplayWrap=false;
        this.handleGetDisplayImages();

      }).catch(err => {

        this.$message.error(err.response.data.message);

      });

    },
    editDisplayWrap() {

      let params={
        categoryid: parseInt(this.categoryid),
        name: this.displayForm.name,
        src: this.displayForm.src,
        filesrc: this.displayForm.filesrc,
        order: parseInt(this.displayForm.order),
        status: parseInt(this.displayForm.status),
        id: this.currentid,
        is_show_at_choose_goods:parseInt(this.displayForm.is_show_at_choose_goods)

      };

      this.$axios.put("/a1/display/category/images", params).then(() => {

        this.$message.success("更新成功");
        this.addDisplayWrap=false;
        this.handleGetDisplayImages();

      }).catch(err => {

        this.$message.error(err.response.data.message);

      });
    },
    handleGetDisplayImages() {

      let params={
        page: this.imagesDataPageInfo.page,
        pagesize: this.imagesDataPageInfo.pagesize,
        categoryid: parseInt(this.categoryid),
      };

      this.$axios.get("/a1/display/category/images", { params: params }).then(res => {
        const { data }=res.data;
        this.imagesData=data.data;
        this.imagesDataPageInfo.total=data.total;
      });
    },
    handleEdit(type,row) {

      switch (type){
        case "edit":
          this.addDisplayWrap=true;
          this.add=false;
          this.currentid=row.id;
          this.displayForm.src=row.imagesSrc
          this.displayForm.name = row.imagesName
          this.displayForm.order = row.imagesOrder
          this.displayForm.status = row.imagesStatus
          this.displayForm.filesrc = row.filesrc
          this.displayForm.is_show_at_choose_goods = row.is_show_at_choose_goods
          break;
        case "del":
          this.$alert('是否删除当前内容。','删除',{
            confirmButtonText: '确定',
            callback: action => {

              if (action === 'cancel'){
                this.$message.info("已取消删除")
              }

              if (action === 'confirm'){
                this.$axios.delete('/a1/display/category/images',{params:{id:row.id}}).then(() => {
                  this.$message.success("删除成功")
                  this.handleGetDisplayImages()
                }).catch(() =>{
                  this.$message.error("删除失败，请联系管理员")
                })
              }
            }
          })
          break
        case "show":
          console.log(row);
          this.bindGoods = true
          this.bindForm.imagesid = parseInt(row.id)
          this.handleGetBindGoods()
          break
      }
    },
    handleRenewRenewSizeChange(val) {
      this.imagesDataPageInfo.pagesize=val;
      this.handleGetDisplayImages();
    },
    handleChangeCurrentPage(val) {
      this.imagesDataPageInfo.page=val;
      this.handleGetDisplayImages();
    },
    handleImgSuccess(res, file) {
      if (res.error === 0) this.displayForm.src=JSON.parse(JSON.stringify(res.data));
      this.displayForm.src=file.response.data;
    },
    handleFileSuccess(res,file){
      if (res.error === 0) this.displayForm.filesrc=JSON.parse(JSON.stringify(res.data));
      this.displayForm.filesrc=file.response.data;
      this.fileList = [{name:file.name,url:this.displayForm.filesrc}]
    },
    handleDownFile(row){
      window.location.href = row.filesrc
    },
    submitBindGoods(){
      if (this.bindForm.goods_number && this.bindForm.goods_item) {
        this.$axios.post("/a1/display/images/bind",{
          imagesid:this.bindForm.imagesid,
          goods_item:this.bindForm.goods_item,
          goods_number:parseInt(this.bindForm.goods_number)
        }).then(() => {
          this.$message.success("绑定成功")
          this.handleGetBindGoods()
        }).catch(res=>{
          this.$message.error(res.response.data.message)
        })
      }else{
        this.$message.info("请填写完整商品编码和规格")
      }
    },
    handleGetBindGoods(){
      this.$axios.get("/a1/display/images/bind",{
        params:{
          display_imagesid:this.bindForm.imagesid
        }
      }).then(res => {
        const { data } = res.data
        this.bindData = data.data
        let total = 0
        this.bindData.forEach(item => {
          total += (item.bind_number * item.goods_price)
        })
        this.bindTotal = total.toFixed(2)
      })
    },
    handleUnbind(row){
      row.id
      this.$axios.post("/a1/display/images/unbind",{
        bindid:row.id
      }).then(res =>{
        console.log(res);
        if (res.data.error === 0) {
          this.$message.success("解除成功")
          this.handleGetBindGoods()
        }else{
          this.$message.success("解除失败")
        }
      })
    },
    handleChangeBindData(val){
      console.log(val)
    }
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
</style>
<style scoped lang="scss">
.display-images {
  padding: 10px;

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 200px;
    line-height: 200px;;
    text-align: center;
  }

  .avatar {
    width: 150px;
    height: 200px;
    display: block;
  }

  .avatar-uploader-file{
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;;
    text-align: center;
  }

  .avatar-file {
    width: 100px;
    height: 100px;
    display: block;
  }


  .display-images-content {
    .banner-viewer {
      width: 150px;
      height: 200px;
    }
  }

  .renew-data-footer{
    padding-top: 10px;
  }

}
</style>

